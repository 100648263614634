import React from 'react'
import './style.css'

const CheckBox = props => {
  const {
    Label,
    Placeholder,
    Value,
    OnChange = {},
    ClassName = '',
    Required = false,
    labelFont = false,
  } = props

  return (
    <span className={`checkbox-container`} style={labelFont ? { fontSize: labelFont } : {}}>
      <div dangerouslySetInnerHTML={{ __html: Label }} />
      <label htmlFor={Placeholder + Label}>
        <input
          id={Placeholder + Label}
          placeholder={Placeholder}
          value={Value}
          type="checkbox"
          required={Required}
          onChange={e => OnChange(e)}
          className={`h-12 mb-10 p-4 w-full py-3 ${ClassName}`}
        />
        <span className="checkmark" />
      </label>
    </span>
  )
}

export default CheckBox

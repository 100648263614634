import React from 'react'

const Button = ({
  text,
  green = false,
  arrow = false,
  className = '',
  bgDark = false,
  wFull = false,
  disabled = false,
}) => {
  let hoverClass = ''
  if (green !== false) {
    hoverClass = bgDark
      ? 'hover:bg-lendis-main-dark hover:border-lendis-main-dark'
      : 'hover:bg-white hover:border-lendis-main'
  } else {
    hoverClass = bgDark
      ? 'hover:bg-lendis-main-dark hover:border-lendis-main-dark'
      : 'hover:bg-lendis-main hover:border-transparent'
  }
  return (
    <button
      disabled={disabled}
      className={`transition-ease-2 bg-transparent rounded w-full mt-6 xl:w-64 font-semibold py-4 px-3 border ${className}
      ${
        disabled
          ? 'cursor-not-allowed bg-lendis-back-gray text-white'
          : green
          ? 'more-info-button-green border-transparent text-white bg-lendis-main hover:text-lendis-main ' +
            hoverClass
          : 'more-info-button text-lendis-main bg-transparent border-lendis-main hover:text-white ' +
            hoverClass
      }
      ${wFull ? 'w-full' : ''}

        `}
    >
      <div className={`${arrow ? 'flex' : ''} items-center justify-between`}>
        <p className={`${arrow ? 'mr-1' : ''} text-sm xl:text-base`}>{text}</p>
        {arrow && (
          <svg
            width="31"
            height="15"
            viewBox="0 0 31 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.6508 8.25508H0.35498V6.54508H25.6508L22.8354 3.72966L24.0446 2.52051L28.9241 7.40008L24.0446 12.2797L22.8354 11.0705L25.6508 8.25508Z"
              fill={disabled ? '#ffffff' : '#00AF54'}
            />
          </svg>
        )}
      </div>
    </button>
  )
}

export default Button

class GoogleTagManagerService {
  fireMQLEvent() {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'MQL',
      currency: 'EUR',
      category: 'Conversion',
      action: 'MQL',
      label: 'Form Submitted',
      value: 0,
    })
  }
}

module.exports = GoogleTagManagerService
